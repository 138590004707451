import React, { useEffect, useState } from "react";
import styles from "../styles/NavBarLanding.module.scss";
import logo from "../imgs/velvet.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Drawer } from "@mui/material";
import ArrowIcon from "../imgs/arrow.svg";
import Select, {components} from 'react-select'

export default function NavBar() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openTools, setOpenTools] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [aboutUsOpen, setAboutUsOpen] = useState(false);

  const handleDrawerToggle = (newOpen) => () => {
    setDrawerOpen(newOpen);
    setAboutUsOpen(false);
    setToolsOpen(false);
  };

  const handleToolsDrawerToggle = () => {
    setToolsOpen(!drawerOpen);
    setDrawerOpen(false);
    setAboutUsOpen(false);
  };

  const handleAboutUsDrawerToggle = () => {
    setAboutUsOpen(!drawerOpen);
    setDrawerOpen(false);
    setToolsOpen(false);
  };

  const handleTooltipToolsClose = () => {
    setOpenTools(false);
  };

  const handleTooltipToolsOpen = () => {
    setOpenTools(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleOpenDrawerTools = () => {
    setDrawerOpen(false);
    setToolsOpen(!toolsOpen);
  };

  const handleOpenDrawerAboutUs = () => {
    setDrawerOpen(false);
    setAboutUsOpen(!aboutUsOpen);
  };

  const closeAllDrawers = () => {
    setToolsOpen(false);
    setAboutUsOpen(false);
    setDrawerOpen(false);
  };

  const openPreviousDrawer = () => {
    setToolsOpen(false);
    setAboutUsOpen(false);
    setDrawerOpen(true);
  };

  useEffect(() => {
    closeAllDrawers();
  }, [pathname]);

  const languages = [
    {
        value: 'en',
        label: 'English',
        flag: "https://flagcdn.com/w320/us.png"
    },
    // {
    //     value: 'de',
    //     label: 'Deutsch',
    //     flag: "https://flagcdn.com/w320/de.png"
    // },
    {
        value: 'ru',
        label: 'Русский',
        flag: "https://flagcdn.com/w320/ru.png"
    },
    // {
    //     value: 'fr',
    //     label: 'Français',
    //     flag: "https://flagcdn.com/w320/fr.png"
    // },
    // {
    //     value: 'ua',
    //     label: 'Українська',
    //     flag: "https://flagcdn.com/w320/ua.png"
    // },
    // {
    //     value: 'tr',
    //     label: 'Turkish',
    //     flag: "https://flagcdn.com/w320/tr.png"
    // },
    // {
    //     value: 'cz',
    //     label: 'Czech',
    //     flag: "https://flagcdn.com/w320/cz.png"
    // },
    // {
    //     value: 'it',
    //     label: 'Italian',
    //     flag: "https://flagcdn.com/w320/it.png"
    // },
    // {
    //     value: 'es',
    //     label: 'Spanish',
    //     flag: "https://flagcdn.com/w320/es.png"
    // }
]

const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
        <div className={styles.single_value_container}>
            <div className={styles.custom_icon}>
                <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5003 6L8.83366 10L4.16699 6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            {children}
        </div>
    </components.DropdownIndicator>
);

const Control = ({ children, ...props }) => {
  return (
      <components.Control {...props}>
          <div className={styles.country_start}>
              <span className={children[0].props.selectProps.value
                  ? styles.flag : styles.icon}>
                  {children[0].props.selectProps.value
                      ? <img src={children[0].props.selectProps.value.flag} alt='flag' />
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" width={'1.7vh'} height={'1.6vh'} viewBox="0 0 17 16" fill="none">
                          <g clipPath="url(#clip0_420_48527)">
                              <ellipse cx="8.84733" cy="6.66667" rx="4.66667" ry="4.66667" stroke="#51525C" />
                              <path d="M3.51465 11.0415C4.77357 12.4481 6.60307 13.3333 8.63931 13.3333C12.4365 13.3333 15.5146 10.2551 15.5146 6.45798C15.5146 4.42173 14.6294 2.59223 13.2229 1.33331" stroke="#51525C" strokeLinecap="round" />
                              <path d="M5.51465 3.0351C5.90477 3.40352 6.70842 4.41053 6.80205 5.49122C6.88957 6.50137 7.5325 7.32219 8.51465 7.33331C8.89209 7.33758 9.27382 7.05481 9.27285 6.66337C9.27255 6.54233 9.25328 6.41857 9.22313 6.30471C9.1812 6.14634 9.17748 5.96415 9.26465 5.77777C9.56959 5.12576 10.1693 4.95062 10.6446 4.59652C10.8554 4.43948 11.0476 4.2738 11.1324 4.14036C11.3665 3.77194 11.6006 3.0351 11.4835 2.66669" stroke="#51525C" />
                              <path d="M13.5146 7.33331C13.3683 7.74711 13.2646 8.83331 11.6116 8.85055C11.6116 8.85055 10.1478 8.85055 9.70863 9.67814C9.35731 10.3402 9.56225 11.0575 9.70863 11.3333" stroke="#51525C" />
                              <path d="M8.84766 14.6666V13.3333" stroke="#51525C" strokeLinecap="round" />
                              <path d="M8.84798 14.6667H7.51465" stroke="#51525C" strokeLinecap="round" />
                              <path d="M10.181 14.6667H8.84766" stroke="#51525C" strokeLinecap="round" />
                          </g>
                          <defs>
                              <clipPath id="clip0_420_48527">
                                  <rect width={16} height={16} fill="white" transform="translate(0.847656)" />
                              </clipPath>
                          </defs>
                      </svg>

                  }
              </span>
              <div className={styles.country}>
                  {children}
              </div>
          </div>
      </components.Control>
  )
};
  const CustomOptionCountry = (props) => {
    const { innerProps, innerRef } = props;
    return (
        <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
            <div className={styles.option_country}  >
                <img  src={props.data.flag} alt='flag'  style={{borderRadius:"50%"}}/>
                {props.data.label}
            </div>
        </div>)
  }

  const colourStylesCountry = {
      control: (styles) => ({
          ...styles,
          backgroundColor: 'transparent',
          width: '100%',
          minHeight: '48px',
          color: '#121926',
          border: '1px solid #26272B',
          boxShadow: 'none',
          borderRadius: '32px',
          fontSize: '14px',
          '.custom_select__indicators': {
              '.custom_select__indicator': {
                  padding: '0.4vh !important'
              }
          }
      }),
      menu: (styles) => ({
          ...styles,
          background: 'transparent',
          border: 'none',
          boxShadow: 'none',
          outlined: 'none',
      }),
      placeholder: (styles) => ({
          ...styles,
          fontSize: '14px',
      }),
      menuList: (styles) => ({
          ...styles,
          outlined: 'none',
          background: '#1D1D20',
          color: '#fff',
          borderRadius: '24px',
          padding: '0.4vh 0.4vh',
          zIndex: '1',
          "::-webkit-scrollbar": {
              width: "4px",
              height: "0px",
          },
          "::-webkit-scrollbar-track": {
              background: "#1D1D20"
          },
          "::-webkit-scrollbar-thumb": {
              background: "#3F3F46",
              zIndex: '0'
          },
          "::-webkit-scrollbar-thumb:hover": {
              background: "#3F3F46"
          }
      }),
      multiValue: (styles) => ({
          ...styles,
          backgroundColor: 'transparent',
          color: '#121926',
          padding: 0,
      }),
      multiValueLabel: (styles) => ({
          ...styles,
          color: '#121926',
          padding: 0,
      }),
      multiValueRemove: (styles) => ({
          ...styles,
          display: 'none'
      }),
      input: (styles) => ({
          ...styles,
          backgroundColor: 'transparent',
          color: '#121926',
          fontSize: '14px'
      }),
      option: (styles, { data, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isFocused || isSelected ? '#161C24' : undefined,
          color: data.color ? data.color : '#FCFCFC',
          fontSize: '1.4vh',
          lineHeight: '1.6vh',
          padding: '12px 4px 12px 12px',
          borderRadius: '32px',
          ':hover': {
              backgroundColor: '#2C2D30',
              color: '#FCFCFC',
          },
      }),
      singleValue: (baseStyles) => ({
          ...baseStyles,
          fontWeight: '400',
          fontFamily: "'Inter', sans-serif",
          color: '#FCFCFC',
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
      }),
      value: (baseStyles) => ({
          ...baseStyles,
          fontSize: '14px',
      }),
  };

  const currentLanguage = i18n.language;
  const currentFlag = languages.find((lang) => lang.value === currentLanguage)?.flag;
  function changeLanguage() {
    const currentIndex = languages.findIndex((lang) => lang.value === currentLanguage);
    const nextIndex = (currentIndex + 1) % languages.length;
    const nextLanguage = languages[nextIndex].value;

    i18n.changeLanguage(nextLanguage);
    secureLocalStorage.setItem('lang', nextLanguage);
    document.documentElement.lang = nextLanguage;
  }

  useEffect(() => {
    const savedLang = secureLocalStorage.getItem("lang") || "en";
    i18n.changeLanguage(savedLang);
  }, []);

  return (
    <div className={styles.NavBar}>
      <div className={styles.NavBarContent}>
        <Link to="/" className={styles.Logo}>
          <img loading="lazy" src={logo} alt="Logo" />
          <span>ZenithHoldings</span>
        </Link>
        <div className={styles.links}>
          <Link to="/accounts">{t("Account types")}</Link>
          <ClickAwayListener onClickAway={handleTooltipToolsClose}>
            <div className={styles.tools}>
              <Tooltip
                classes={{ tooltip: styles.tooltip }}
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipToolsClose}
                open={openTools}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <div className={styles.tools_tooltip}>
                    <div className={styles.column}>
                      <div className={styles.column_title}>{t("Heat-map")}</div>
                      <div className={styles.links_tooltip}>
                        <Link
                          to="/tools/forexHeatMap"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Forex Heat-map")}
                        </Link>
                        <Link
                          to="/tools/stockHeatMap"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Heat-map of the stock")}
                        </Link>
                        <Link
                          to="/tools/etfHeatMap"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("ETF Heat-map")}
                        </Link>
                        <Link
                          to="/tools/CryptoHeatMap"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Crypto Coin Heat-map")}
                        </Link>
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.column_title}>{t("Analysis")}</div>
                      <div className={styles.links_tooltip}>
                        {/* <Link to="/tools" onClick={handleTooltipToolsClose}>
                          {t("Technical Analysis")}
                        </Link> */}
                        <Link
                          to="/tools/cryptocurrencyMarket"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Cryptocurrency Market")}
                        </Link>
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.column_title}>{t("News")}</div>
                      <div className={styles.links_tooltip}>
                        <Link
                          to="/tools/economicCalendar"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Economic Calendar")}
                        </Link>
                        <Link
                          to="/tools/topStories"
                          onClick={handleTooltipToolsClose}
                        >
                          {t("Top Stories")}
                        </Link>
                      </div>
                    </div>
                    {/* <div className={styles.contacts}>
                      <div className={styles.column_title}>
                        {t("Contact Us")}
                      </div>
                      <div className={styles.contacts_content}>
                        <div className={styles.address}>
                          Address: 123 Main Street, Suite 100, New York, NY
                          10001
                        </div>
                        <div className={styles.contact_data}>
                          <p>Phone: +1 (555) 123-4567</p>
                          <p>Email: info@example.com</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                }
              >
                <button
                  className={`${openTools ? styles.active : ""}`}
                  onClick={
                    !openTools
                      ? handleTooltipToolsOpen
                      : handleTooltipToolsClose
                  }
                >
                  {t("Tools")}
                  {openTools ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={17}
                      height={16}
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M13.1668 10L8.50016 6L3.8335 10"
                        stroke="#FCFCFC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={17}
                      height={16}
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M13.1668 6L8.50016 10L3.8335 6"
                        stroke="#FCFCFC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </Tooltip>
            </div>
          </ClickAwayListener>
          <Link to="/bonuses">{t("Bonuses")}</Link>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div className={styles.tools}>
              <Tooltip
                classes={{ tooltip: styles.tooltip }}
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <div className={styles.tools_tooltip}>
                    <div className={styles.column}>
                      <div className={styles.column_title}>{t("Partners")}</div>
                      <div className={styles.links_tooltip}>
                        <Link to="/affiliate" onClick={handleTooltipClose}>
                          {t("Affiliate program")}
                        </Link>
                        {/* <Link>{t("Review of Zenith Holdings")}</Link>
                        <Link>{t("AML and KYC Policies")}</Link>
                        <Link>{t("Anti-money laundering policy")}</Link> */}
                      </div>
                    </div>
                    <div className={styles.column_center}>
                      <div className={styles.column_title}>{t("Company")}</div>
                      <div className={styles.links_tooltip}>
                        <Link to="/about"
                          onClick={handleTooltipClose}>{t("About company")}</Link>
                        {/* <Link>{t("Contact")}</Link> */}
                        <Link to="/privacy" onClick={handleTooltipClose}>{t("Privacy policy")}</Link>
                      </div>
                    </div>
                    {/* <div className={styles.contacts}>
                      <div className={styles.column_title}>
                        {t("Contact Us")}
                      </div>
                      <div className={styles.contacts_content}>
                        <div className={styles.address}>
                          Address: 123 Main Street, Suite 100, New York, NY
                          10001
                        </div>
                        <div className={styles.contact_data}>
                          <p>Phone: +1 (555) 123-4567</p>
                          <p>Email: info@example.com</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                }
              >
                <button
                  className={`${open ? styles.active : ""}`}
                  onClick={!open ? handleTooltipOpen : handleTooltipClose}
                >
                  {t("About us")}
                  {open ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={17}
                      height={16}
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M13.1668 10L8.50016 6L3.8335 10"
                        stroke="#FCFCFC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={17}
                      height={16}
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M13.1668 6L8.50016 10L3.8335 6"
                        stroke="#FCFCFC"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
        <div className={styles.Navigation}>
          <span onClick={() => changeLanguage()} className={styles.lang}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_206_13223)">
                <path
                  d="M14.6668 8.00004C14.6668 8.87552 14.4944 9.74243 14.1594 10.5513C13.8243 11.3601 13.3333 12.095 12.7142 12.7141C12.0952 13.3331 11.3602 13.8242 10.5514 14.1592C9.74255 14.4943 8.87564 14.6667 8.00016 14.6667C7.12468 14.6667 6.25778 14.4943 5.44894 14.1592C4.6401 13.8242 3.90517 13.3331 3.28612 12.7141C2.66706 12.095 2.176 11.3601 1.84097 10.5513C1.50593 9.74243 1.3335 8.87552 1.3335 8.00004C1.3335 7.12456 1.50593 6.25765 1.84097 5.44882C2.176 4.63998 2.66706 3.90505 3.28612 3.286C3.90518 2.66694 4.6401 2.17587 5.44894 1.84084C6.25778 1.50581 7.12468 1.33337 8.00016 1.33337C8.87564 1.33337 9.74255 1.50581 10.5514 1.84084C11.3602 2.17588 12.0952 2.66694 12.7142 3.286C13.3333 3.90505 13.8243 4.63998 14.1594 5.44882C14.4944 6.25766 14.6668 7.12456 14.6668 8.00004L14.6668 8.00004Z"
                  stroke="#FCFCFC"
                />
                <path
                  d="M10.6668 8.00004C10.6668 8.87552 10.5979 9.74243 10.4638 10.5513C10.3298 11.3601 10.1334 12.095 9.88578 12.7141C9.63816 13.3331 9.34419 13.8242 9.02065 14.1592C8.69712 14.4943 8.35035 14.6667 8.00016 14.6667C7.64997 14.6667 7.30321 14.4943 6.97967 14.1592C6.65614 13.8242 6.36217 13.3331 6.11454 12.7141C5.86692 12.095 5.6705 11.3601 5.53648 10.5513C5.40247 9.74243 5.3335 8.87552 5.3335 8.00004C5.3335 7.12456 5.40247 6.25765 5.53648 5.44882C5.6705 4.63998 5.86692 3.90505 6.11454 3.286C6.36217 2.66694 6.65614 2.17587 6.97967 1.84084C7.30321 1.50581 7.64997 1.33337 8.00016 1.33337C8.35035 1.33337 8.69712 1.50581 9.02065 1.84084C9.34419 2.17588 9.63816 2.66694 9.88578 3.286C10.1334 3.90505 10.3298 4.63998 10.4638 5.44882C10.5979 6.25766 10.6668 7.12456 10.6668 8.00004L10.6668 8.00004Z"
                  stroke="#FCFCFC"
                />
                <path
                  d="M1.3335 8H14.6668"
                  stroke="#FCFCFC"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_206_13223">
                  <rect width={16} height={16} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <Link to="/login" className={styles.Login}>
            {t("Login")}
          </Link>
          <Link to="/register" className={styles.Cabinet}>
            {t("Register")}
          </Link>
        </div>
        <div className={styles.mobileButton} onClick={handleDrawerToggle(!drawerOpen)}>
          {drawerOpen || toolsOpen || aboutUsOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M12 4.00003L4 12M3.99997 4L11.9999 12" stroke="#FCFCFC" stroke-width="1.5" stroke-linecap="round"/>
</svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.3335 4.66669L2.66683 4.66669"
                stroke="#FCFCFC"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M13.3335 8L2.66683 8"
                stroke="#FFF"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M13.3335 11.3333L2.66683 11.3333"
                stroke="#FCFCFC"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          )}
        </div>
      </div>
          <Drawer anchor="top" open={drawerOpen} onClose={handleDrawerToggle(false)}>
            <div className={styles.menu}>
              <div className={styles.menu__title}>{t("Menu")}</div>
              <div className={styles.menu__container}>
                <div
                  className={styles.menu__item}
                  onClick={() => {
                    navigate("/accounts");
                  }}
                >
                  <span>{t("01")}</span>
                  <p>{t("Account types")}</p>
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => handleOpenDrawerTools()}
                >
                  <span>{t("02")}</span>
                  <div className={styles.box}>
                    <p>{t("Tools")}</p>
                    <img src={ArrowIcon} alt="arrow" />
                  </div>
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => {
                    navigate("/bonuses");
                  }}
                >
                  <span>{t("03")}</span>
                  <p>{t("Bonuses")}</p>
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => handleOpenDrawerAboutUs()}
                >
                  <span>{t("04")}</span>
                  <div className={styles.box}>
                    <p>{t("About us")}</p>
                    <img src={ArrowIcon} alt="arrow" />
                  </div>
                </div>
              </div>
                <div className={styles.language}>
                    <Select
                        value={languages.find(el => el.value === i18n.language)}
                        classNamePrefix="custom_select"
                        className={`${styles.select_select} custom-select-container`}
                        placeholder={'Country'}
                        menuPlacement='auto'
                        options={languages}
                        styles={colourStylesCountry}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOptionCountry, Control }}
                        onChange={(selectedOption) => {i18n.changeLanguage(selectedOption.value);secureLocalStorage.setItem('lang', selectedOption.value);}}
                    />
                </div>
                <div className={styles.login_register}>
                    <Link to='/login' className={styles.login}>{t('Login')}</Link>
                    <Link to='/register' className={styles.register}>{t('Register')}</Link>
                </div>
            </div>
          </Drawer>
          <Drawer
            anchor="top"
            open={toolsOpen}
            onClose={handleToolsDrawerToggle}
          >
            <div className={styles.menu__tools}>
              <div
                className={styles.title}
                onClick={() => openPreviousDrawer()}
              >
                <img src={ArrowIcon} alt="arrow" className={styles.icon} />
                <h1>{t("Tools")}</h1>
              </div>
              <span className={styles.first_title}>{t("Heat-map")}</span>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/forexHeatMap");
                }}
              >
                {t("Forex Heat-map")}
              </p>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/stockHeatMap");
                }}
              >
                {t("Heat-map of the stock")}
              </p>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/etfHeatMap");
                }}
              >
                {t("ETF Heat-map")}
              </p>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/CryptoHeatMap");
                }}
              >
                {t("Crypto Coin Heat-map")}
              </p>
              <span>{t("Analysis")}</span>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/cryptocurrencyMarket");
                }}
              >
                {t("Cryptocurrency Market")}
              </p>
              <span>{t("News")}</span>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/economicCalendar");
                }}
              >
                {t("Economic Calendar")}
              </p>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/tools/topStories");
                }}
              >
                {t("Top Stories")}
              </p>
            </div>
          </Drawer>
          <Drawer
            anchor="top"
            open={aboutUsOpen}
            onClose={handleAboutUsDrawerToggle}
          >
            <div className={styles.menu__tools}>
              <div
                className={styles.title}
                onClick={() => openPreviousDrawer()}
              >
                <img src={ArrowIcon} alt="arrow" className={styles.icon} />
                <h1>{t("About us")}</h1>
              </div>
              <span className={styles.first_title}>{t("Partners")}</span>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/affiliate");
                }}
              >
                {t("Affiliate program")}
              </p>
              <span>{t("Company")}</span>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/about");
                }}
              >
                {t("About company")}
              </p>
              <p
                onClick={() => {
                  closeAllDrawers();
                  navigate("/privacy");
                }}
              >
                {t("Privacy policy")}
              </p>
            </div>
          </Drawer>
    </div>
  );
}
