import React from 'react'
import styles from '../styles/FooterLanding.module.scss';
import logo from '../imgs/logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function FooterLanding() {
     const {t} = useTranslation();
    const methods = [
        {
            id:1,
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width={208} height={134} viewBox="0 0 208 134" fill="none">
                    <rect width={208} height={134} rx={32} fill="#18181B" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M103.627 94.5208C111.77 88.1123 117 78.1669 117 67C117 55.8331 111.77 45.8877 103.627 39.4792C109.58 34.7946 117.09 32 125.253 32C144.583 32 160.253 47.67 160.253 67C160.253 86.33 144.583 102 125.253 102C117.09 102 109.58 99.2054 103.627 94.5208ZM103.627 94.5208C97.6734 99.2054 90.163 102 82 102C62.67 102 47 86.33 47 67C47 47.67 62.67 32 82 32C90.163 32 97.6734 34.7946 103.627 39.4792C95.4828 45.8877 90.2531 55.8331 90.2531 67C90.2531 78.1669 95.4828 88.1123 103.627 94.5208Z" fill="#51525C" />
                    <path d="M103.626 94.5211C111.77 88.1127 117 78.1672 117 67.0003C117 55.8334 111.77 45.888 103.626 39.4795C95.4826 45.888 90.2529 55.8334 90.2529 67.0003C90.2529 78.1672 95.4826 88.1127 103.626 94.5211Z" fill="#70707B" />
                </svg>

        },
        {
            id:2,
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width={208} height={134} viewBox="0 0 208 134" fill="none">
                    <path d="M0 32C0 14.3269 14.3269 0 32 0H176C193.673 0 208 14.3269 208 32V102C208 119.673 193.673 134 176 134H32C14.3269 134 0 119.673 0 102V32Z" fill="#18181B" />
                    <path d="M141.811 77.654C136.473 99.0695 114.783 112.147 93.3283 106.806C71.9128 101.452 58.8567 79.7615 64.1943 58.346C69.5318 36.9305 91.209 23.8535 112.612 29.1942C134.079 34.4696 147.135 56.2254 141.811 77.654Z" fill="#51525C" />
                    <path d="M121.941 62.7456C122.66 57.4123 118.608 54.6279 113.053 52.7456L114.778 45.5299L110.386 44.4711L108.713 51.5299C107.549 51.2554 106.373 50.9809 105.209 50.7456L106.883 43.6868L102.438 42.6279L100.713 49.8436C99.7715 49.6214 98.8173 49.4515 97.8761 49.2292L91.8238 47.7783L90.7127 52.4449C90.7127 52.4449 93.9938 53.1639 93.8761 53.2293C95.6539 53.6737 95.9938 54.8371 95.9284 55.726L93.9807 63.9482C94.0983 64.0005 94.2552 64.0005 94.4251 64.1182C94.2552 64.0659 94.1506 64.0659 93.9807 64.0136L91.314 75.5169C91.0918 76.0659 90.5297 76.8502 89.314 76.5757C89.3663 76.628 86.0853 75.7914 86.0853 75.7914L84.0068 80.8502L89.7323 82.1836C90.7911 82.4581 91.85 82.6803 92.8434 82.9679L91.1179 90.2489L95.5101 91.3078L97.2356 84.0921C98.4643 84.4188 99.6278 84.7064 100.739 84.9809L99.0134 92.1444L103.406 93.2032L105.131 85.9221C112.634 87.2555 118.242 86.6411 120.517 79.8045C122.347 74.3535 120.347 71.2424 116.347 69.1901C119.275 68.5234 121.392 66.628 121.941 62.7456ZM112.059 76.9025C110.778 82.3535 101.562 79.5169 98.6081 78.7979L100.883 69.1247C103.889 69.8567 113.392 71.1901 112.059 76.9025ZM113.275 62.7456C112.111 67.6868 104.438 65.3077 101.941 64.6933L104.059 55.9221C106.504 56.5234 114.504 57.5822 113.275 62.7456Z" fill="#131316" />
                </svg>
        },
        {
            id:3,
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width="208" height="134" viewBox="0 0 208 134" fill="none">
                    <path d="M0 32C0 14.3269 14.3269 0 32 0H176C193.673 0 208 14.3269 208 32V102C208 119.673 193.673 134 176 134H32C14.3269 134 0 119.673 0 102V32Z" fill="#18181B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M44.5013 47.5044H32.5093C31.6887 47.5044 30.9907 48.1007 30.8628 48.9104L26.0126 79.658C25.9162 80.2649 26.3861 80.8117 27.0016 80.8117H32.7267C33.5473 80.8117 34.2452 80.2154 34.3732 79.404L35.6814 71.111C35.8076 70.2989 36.5072 69.7033 37.3261 69.7033H41.1224C49.0219 69.7033 53.5807 65.881 54.7719 58.3069C55.3083 54.9933 54.7947 52.3896 53.2423 50.5656C51.5383 48.564 48.5148 47.5044 44.5013 47.5044ZM45.8848 58.7345C45.2292 63.0371 41.9413 63.0371 38.7622 63.0371H36.9526L38.2221 55.0014C38.2975 54.5162 38.7183 54.1584 39.2094 54.1584H40.0387C42.2043 54.1584 44.2473 54.1584 45.303 55.3924C45.9319 56.1292 46.125 57.2229 45.8848 58.7345ZM80.3484 58.5965H74.6056C74.1163 58.5965 73.6936 58.9544 73.6181 59.4404L73.3641 61.0461L72.9624 60.4643C71.7193 58.6598 68.9473 58.057 66.1803 58.057C59.8338 58.057 54.4141 62.8627 53.3584 69.6043C52.8098 72.9666 53.5896 76.1821 55.4974 78.4247C57.2477 80.4864 59.7519 81.3456 62.7307 81.3456C67.8438 81.3456 70.6798 78.0579 70.6798 78.0579L70.4234 79.6539C70.3268 80.2641 70.7967 80.8109 71.4085 80.8109H76.5816C77.4044 80.8109 78.0982 80.2146 78.228 79.4032L81.3318 59.7503C81.43 59.1458 80.9618 58.5965 80.3484 58.5965ZM72.3433 69.7723C71.7891 73.0526 69.1851 75.2547 65.8646 75.2547C64.1971 75.2547 62.8638 74.72 62.0085 73.7066C61.1598 72.7005 60.8368 71.2676 61.107 69.6725C61.6247 66.4197 64.2725 64.1463 67.5427 64.1463C69.1729 64.1463 70.4988 64.6874 71.372 65.7098C72.2467 66.7426 72.594 68.1836 72.3433 69.7723ZM105.162 58.5957H110.933C111.741 58.5957 112.213 59.502 111.753 60.1649L92.56 87.8665C92.2492 88.3152 91.7372 88.5822 91.1903 88.5822H85.4265C84.615 88.5822 84.1411 87.6686 84.611 87.0041L90.5874 78.5691L84.2312 59.9174C84.0113 59.2691 84.49 58.5957 85.1798 58.5957H90.8503C91.5871 58.5957 92.2371 59.0793 92.4497 59.7844L95.8229 71.0494L103.783 59.3268C104.094 58.8692 104.612 58.5957 105.162 58.5957Z" fill="#51525C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M167.733 79.6552L172.654 48.3454C172.73 47.8594 173.151 47.5016 173.64 47.5H179.181C179.793 47.5 180.263 48.0485 180.166 48.6554L175.313 79.4013C175.186 80.2126 174.488 80.809 173.666 80.809H168.718C168.106 80.809 167.636 80.2621 167.733 79.6552ZM130.041 47.5016H118.047C117.228 47.5016 116.53 48.098 116.402 48.9077L111.552 79.6552C111.456 80.2621 111.925 80.809 112.538 80.809H118.692C119.264 80.809 119.754 80.3919 119.843 79.824L121.219 71.1083C121.346 70.2961 122.045 69.7006 122.864 69.7006H126.658C134.559 69.7006 139.117 65.8782 140.309 58.3041C140.847 54.9905 140.331 52.3868 138.779 50.5629C137.076 48.5613 134.055 47.5016 130.041 47.5016ZM131.425 58.7317C130.771 63.0344 127.483 63.0344 124.302 63.0344H122.494L123.765 54.9986C123.841 54.5134 124.258 54.1556 124.751 54.1556H125.58C127.744 54.1556 129.789 54.1556 130.844 55.3897C131.473 56.1264 131.665 57.2202 131.425 58.7317ZM165.885 58.5938H160.146C159.653 58.5938 159.234 58.9516 159.16 59.4376L158.907 61.0433L158.503 60.4616C157.26 58.6571 154.49 58.0542 151.723 58.0542C145.376 58.0542 139.958 62.86 138.902 69.6016C138.355 72.9639 139.132 76.1793 141.04 78.4219C142.793 80.4836 145.294 81.3429 148.273 81.3429C153.386 81.3429 156.221 78.0552 156.221 78.0552L155.966 79.6512C155.869 80.2613 156.339 80.8082 156.955 80.8082H162.126C162.944 80.8082 163.642 80.2118 163.77 79.4004L166.876 59.7476C166.971 59.1431 166.501 58.5938 165.885 58.5938ZM157.881 69.7695C157.33 73.0499 154.723 75.2519 151.401 75.2519C149.737 75.2519 148.401 74.7173 147.545 73.7039C146.696 72.6978 146.378 71.2649 146.645 69.6698C147.165 66.417 149.809 64.1435 153.079 64.1435C154.71 64.1435 156.036 64.6847 156.91 65.707C157.788 66.7399 158.135 68.1809 157.881 69.7695Z" fill="#51525C"/>
                </svg>
        },
        {
            id:4,
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width={208} height={134} viewBox="0 0 208 134" fill="none">
                <path d="M0 32C0 14.3269 14.3269 0 32 0H176C193.673 0 208 14.3269 208 32V102C208 119.673 193.673 134 176 134H32C14.3269 134 0 119.673 0 102V32Z" fill="#18181B" />
                <path d="M72.7283 67.3426C72.7283 74.7489 78.5451 80.4904 86.0267 80.4904C88.1433 80.4904 89.9527 80.0731 92.1874 79.023V73.2388C90.2234 75.2028 88.4832 75.9965 86.2546 75.9965C81.3049 75.9965 77.792 72.4084 77.792 67.306C77.792 62.4702 81.4188 58.6541 86.0267 58.6541C88.3713 58.6541 90.146 59.4885 92.1874 61.4872V55.705C90.0321 54.6121 88.2573 54.1582 86.1427 54.1582C78.6977 54.1582 72.7283 60.0177 72.7283 67.3426Z" fill="#51525C" />
                <path d="M60.1157 61.2246C60.1157 62.5842 60.9827 63.3047 63.9318 64.3956C69.5227 66.441 71.1794 68.2524 71.1794 72.2557C71.1794 77.1322 67.5953 80.527 62.4888 80.527C58.75 80.527 56.0309 79.0555 53.7657 75.7319L56.9407 72.675C58.0723 74.8629 59.961 76.0352 62.3036 76.0352C64.4976 76.0352 66.1197 74.523 66.1197 72.4816C66.1197 71.4233 65.6272 70.5135 64.6462 69.8745C64.1516 69.5712 63.1726 69.1174 61.2493 68.4396C56.6333 66.7788 55.0499 65.0021 55.0499 61.5299C55.0499 57.4065 58.4508 54.3108 62.9081 54.3108C65.6699 54.3108 68.1977 55.2552 70.3123 57.1053L67.7398 60.4716C66.4596 59.0367 65.2506 58.4302 63.7771 58.4302C61.6584 58.4302 60.1157 59.6371 60.1157 61.2246Z" fill="#51525C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.215 54.7281H24V79.9226H31.1763C34.9904 79.9226 37.7482 79.023 40.1661 77.0142C43.0399 74.635 44.7393 71.0489 44.7393 67.3426C44.7393 59.9078 39.183 54.7281 31.215 54.7281ZM36.9544 73.656C35.4097 75.0481 33.4049 75.6567 30.2299 75.6567H28.9111V58.9981H30.2299C33.4049 58.9981 35.3303 59.5639 36.9544 61.0354C38.6559 62.5476 39.6756 64.8922 39.6756 67.306C39.6756 69.7239 38.6559 72.1438 36.9544 73.656Z" fill="#51525C" />
                <path d="M51.9157 54.7281H47.0005V79.9226H51.9157V54.7281Z" fill="#51525C" />
                <path d="M124.434 54.7281L131.154 71.6493L137.96 54.7281H143.287L132.402 80.5698H129.758L119.067 54.7281H124.434Z" fill="#51525C" />
                <path d="M145.509 79.9226H159.447V75.6567H150.42V68.8569H159.113V64.5889H150.42V58.9981H159.447V54.7281H145.509V79.9226Z" fill="#51525C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M169.987 54.7281C175.655 54.7281 178.908 57.4493 178.908 62.1649C178.908 66.0238 176.866 68.5557 173.164 69.3087L181.096 79.9226H175.053L168.251 69.8012H167.608V79.9226H162.701V54.7281H169.987ZM167.608 66.3271H169.047C172.185 66.3271 173.848 64.9634 173.848 62.4316C173.848 59.9791 172.185 58.6948 169.122 58.6948H167.608V66.3271Z" fill="#51525C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M182.824 56.049C182.824 55.6053 182.516 55.3631 181.981 55.3631H181.269V57.5856H181.798V56.7247L182.419 57.5856H183.068L182.337 56.6698C182.649 56.5843 182.824 56.3563 182.824 56.049ZM181.887 56.3523H181.798V55.7702H181.894C182.156 55.7702 182.292 55.8658 182.292 56.0572C182.292 56.2525 182.154 56.3523 181.887 56.3523Z" fill="#51525C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M180.151 56.4702C180.151 55.3936 181.01 54.5327 182.087 54.5327C183.137 54.5327 184 55.4099 184 56.4702C184 57.5367 183.137 58.4078 182.087 58.4078C181.018 58.4078 180.151 57.5448 180.151 56.4702ZM180.536 56.4723C180.536 57.3556 181.23 58.0618 182.079 58.0618C182.925 58.0618 183.613 57.3474 183.613 56.4723C183.613 55.6052 182.925 54.8807 182.079 54.8807C181.224 54.8807 180.536 55.589 180.536 56.4723Z" fill="#51525C" />
                <g filter="url(#filter0_i_810_325)">
                    <path d="M107.083 80.688C99.7194 80.688 93.748 74.7166 93.748 67.353V67.335C93.748 63.6712 95.2316 60.3377 97.6311 57.9383C97.6311 57.9197 97.6497 57.9197 97.6497 57.9016C97.6677 57.9016 97.6677 57.8831 97.6863 57.8831C100.086 55.4836 103.42 54 107.083 54H107.102C114.465 54 120.437 59.9714 120.437 67.335V67.353C120.437 71.0168 118.953 74.3322 116.553 76.7502C116.553 76.7683 116.535 76.7683 116.535 76.7869C116.516 76.7869 116.516 76.8049 116.498 76.8049C114.08 79.2044 110.765 80.688 107.102 80.688H107.083Z" fill="#51525C" />
                </g>
                <defs>
                    <filter id="filter0_i_810_325" x="93.748" y={54} width="27.6654" height="28.1534" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="0.976924" dy="1.46539" />
                    <feGaussianBlur stdDeviation="0.976924" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_810_325" />
                    </filter>
                </defs>
                </svg>

        },
        {
            id:5,
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" width="208" height="134" viewBox="0 0 208 134" fill="none">
                    <path d="M0 32C0 14.3269 14.3269 0 32 0H176C193.673 0 208 14.3269 208 32V102C208 119.673 193.673 134 176 134H32C14.3269 134 0 119.673 0 102V32Z" fill="#18181B"/>
                    <path d="M102.728 45.1627L93.0263 90.514H81.2948L90.9981 45.1627H102.728ZM152.087 74.4462L158.263 57.4152L161.818 74.4462H152.087ZM165.176 90.514H176.028L166.559 45.1627H156.542C154.292 45.1627 152.393 46.4717 151.547 48.4899L133.946 90.514H146.266L148.712 83.7418H163.764L165.176 90.514ZM134.558 75.7064C134.608 63.7367 118.005 63.0778 118.12 57.731C118.154 56.1018 119.706 54.3736 123.095 53.9301C124.779 53.7104 129.408 53.5425 134.664 55.9611L136.723 46.344C133.899 45.3191 130.266 44.333 125.748 44.333C114.152 44.333 105.991 50.498 105.922 59.3257C105.849 65.8539 111.748 69.4969 116.194 71.6672C120.764 73.8907 122.3 75.3146 122.28 77.3026C122.249 80.3471 118.634 81.6877 115.261 81.7408C109.361 81.8341 105.938 80.149 103.209 78.8772L101.083 88.8145C103.823 90.0733 108.883 91.1671 114.132 91.223C126.455 91.223 134.518 85.1341 134.558 75.7064ZM85.9642 45.1627L66.9567 90.514H54.5535L45.2004 54.3205C44.632 52.0913 44.1382 51.276 42.4115 50.3358C39.5938 48.8071 34.9359 47.3703 30.8379 46.4804L31.1178 45.1627H51.0798C53.6233 45.1627 55.9128 46.8564 56.4898 49.7861L61.4304 76.0308L73.6384 45.1627H85.9642Z" fill="#51525C"/>
                </svg>
        },
    ];
    return (
        <div className={styles.footer}>
            <div className={styles.footerBody}>
                <div className={styles.methods}>
                    {methods.map(el=>
                        <>
                            {el.icon}
                        </>
                    )}
                </div>
                <div className={styles.footer_content}>
                    <div className={styles.navigation}>
                        <img src={logo} className={styles.logo} alt='logo'/>
                        <div className={styles.links}>
                            <Link to='/accounts'>{t('Account types')}</Link>
                            <Link to='/tools/stockHeatMap'>{t('Instruments')}</Link>
                            <Link to='/bonuses'>{t('Promotion and bonuses')}</Link>
                            <Link to="/about">{t('About us')}</Link>
                        </div>
                        <div className={styles.login_register}>
                            <Link to='/login' className={styles.login}>{t('Login')}</Link>
                            <Link to='/register' className={styles.register}>{t('Register')}</Link>
                        </div>
                    </div>
                    <hr />
                    <div className={styles.warning}>
                    ZenithHoldings<br/>
                    Trust Company Complex, Ajeltake Road, Ajeltake Island, Majuro, Republic of the Marshall Islands MH96960 REGULATED BY BCBIR CERTIFICATE BFXT 0310 BF V25
                    </div>
                    <div className={styles.age}>© 1999 ZenithHoldings</div>
                </div>
            </div>
        </div>
    )
}
